<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Conversations</h2>

    <v-alert dismissible width="fit-content">
      From this page you can access & send messages in conversations between
      clients and virtual assistants. <br />
      Navigate the table below & click view on desired conversation to go to it.
    </v-alert>

    <table-with-filters :options="tableOptions" :customColumns="customColumns">
      <template v-slot:client-name="{ item }">
        <div>
          <!-- <v-avatar size="30px">
            <img :src="resolveProfilePic(item.client_conv.profile)" alt="" loading="lazy"/>
          </v-avatar> -->
          <span class="ml-2">{{ item.client_conv.first_name }} {{ item.client_conv.last_name }}</span>
        </div>
      </template>

      <template v-slot:va-name="{ item }">
        <div>
          <!-- <v-avatar size="30px">
            <img :src="resolveProfilePic(item.user.profile)" alt="" loading="lazy"/>
          </v-avatar> -->
          <span class="ml-2">{{ item.user.first_name }} {{ item.user.last_name }}</span>
        </div>
      </template>

      <template v-slot:view-btn="{ item }">
        <v-btn color="primary" small @click="setConversation(item)">
          View
        </v-btn>
      </template>
    </table-with-filters>

    <active-conversation-preview v-if="activeConversation">
    </active-conversation-preview>
  </div>
</template>

<script>
import TableWithFilters from "@/components/common/TableWithFilters.vue";
import ActiveConversationPreview from "@/components/conversations/ActiveConversationPreview.vue";
import { useMessages } from "@/composables/chat/messages";
import { resolveProfilePic } from "@/helpers";

export default {
  name: "Conversations",
  components: { TableWithFilters, ActiveConversationPreview },
  setup() {
    const { activeConversation, setConversation } = useMessages();

    const tableHeaders = [
      { text: "Client", value: "client_conv", sortable: false },
      { text: "Virtual Assistant", value: "user" , sortable: false},
      { text: "View Conversation", value: "viewBtn", sortable: false },
    ];

    const customColumns = [
      { slotName: "client-name", itemValue: "client_conv" },
      { slotName: "va-name", itemValue: "user" },
      { slotName: "view-btn", itemValue: "viewBtn" },
    ];

    return {
      activeConversation,
      resolveProfilePic,
      setConversation,

      tableOptions: {
        endpoint: "chat/get-conversation-list",
        headers: tableHeaders,
        filtersTitle: "",
        filterFields: [],
      },
      customColumns,
    };
  },
};
</script>
