<template>
  <div>
    <v-dialog v-model="activeConversation" persistent width="50%">

      <v-card>

        <v-card-title style="position: sticky;top: 0;background: #fff;z-index: 10;">
          <span>
            Chat
          </span>
          <v-btn @click="resetConversation" class="ml-auto" text> Close </v-btn>
        </v-card-title>

        <hr />

        <div class="d-flex flex-row flex-wrap">
          <inbox-messages></inbox-messages>
          <!-- <online-users></online-users> -->
        </div>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
// import OnlineUsers from "@/components/conversations/chat/OnlineUsers.vue";
import InboxMessages from "@/components/conversations/chat/InboxMessages.vue";
import { useMessages } from "@/composables/chat/messages";

export default {
  name: "ActiveConversationPreview",
  components: {
    // OnlineUsers, 
    InboxMessages
  },
  setup() {
    const {
      state: msgsState,
      activeConversation,
      setConversation,resetConversation
    } = useMessages();

    return {
      msgsState,
      activeConversation,
      setConversation,
      resetConversation
    };
  },
};
</script>

